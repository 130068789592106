import React from 'react'
import { v4 } from 'uuid'
import { Stack, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Faqs,
  Video,
  Checklist,
  Characteristics,
} from '../components/landingPage'

import { H1, P, Image, RowContent, ColImage, ColInfo, Section } from '../ui'
import ButtonBook from '../components/buttonBook'
import Features from '../components/features'
// import FloatingBook from '../components/floatingBook'

import logo from '../images/logo-white.png'

function ChecklistCTA() {
  return (
    <Checklist
      title="¿Estás listo para la aventura con tu familia?"
      subtitle="Una experiencia inolvidable para toda la familia. ¡Conoce uno de los tours más completos al mejor precio!"
      list={[
        'Incluye transportación para tu familia',
        '100% el mejor precio de Cancún',
        'Seguridad y confianza',
      ]}
    >
      <ButtonBook message="Hola, quiero la promoción del tour 4x1..." maxWidth="420px">
        Quiero ir al tour 4x1
      </ButtonBook>
    </Checklist>
  )
}

function SummerSpecialPage({ data }) {
  // const { t } = useTranslation()

  return (
    <LandingPage title="Promoción 4x1: Tulum, Coba, Cenotes, Playa del Carmen">
      <Header
        image={
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.75 }}
          />
        }
      >
        <Stack
          spacing={4}
          maxWidth="560px"
          alignSelf={{ base: 'center', md: 'flex-start' }}
          justifyContent="center"
        >
          <Flex
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            maxWidth="200px"
            height="auto"
            width="100%"
            marginX="auto"
            opacity="0.85"
          >
            <img style={{ width: '100%', maxWidth: '80px' }} src={logo} />
          </Flex>
          <H1 color="white" fontSize="5xl" textAlign="center">
            Tour 4x1: el tour que te da más por tu dinero
          </H1>
          <P color="white" textAlign="center" fontSize="lg">
            Una experiencia inolvidable para toda la familia. Conoce Tulum, Coba, Cenotes, Playa del
            Carmen, uno de los tours más completos al mejor precio.
          </P>
          <ButtonBook message="Hola, quiero la promoción Black Friday de Chichen Itzá...">
            Quiero ir al tour 4x1
          </ButtonBook>
          <P textAlign="center" color="white" lineHeight="shorter" fontSize="sm">
            Términos y condiciones de la promoción.
          </P>
        </Stack>
      </Header>

      <Gallery
        title="Fotos de la experiencia"
        viewPhotos="Ver fotos"
        images={data.gallery.edges.map((image) => (
          <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
        ))}
      />

      <ChecklistCTA />

      {/* <Video
        title="Mira este vídeo sobre la experiencia"
        url="https://www.youtube.com/watch?v=E6RzRynbot8"
      /> */}

      <Characteristics
        title="¿Qué es lo que obtendrás en esta experiencia?"
        list={[
          {
            image: <Image fixed={data.why1.childImageSharp.fixed} layout="background" />,
            title: 'Ruinas Tulum en la playa',
            desc:
              'Conoce las ruinas mayas de Tulum, las únicas que tienen una hermosa vista a la playa.',
          },
          {
            image: <Image fixed={data.why2.childImageSharp.fixed} layout="background" />,
            title: 'Conoce las ruinas Cobá',
            desc:
              'Descubre el enigmático Cobá, en donde se encuentra el templo más alto de la península de Yucatán.',
          },
          {
            image: <Image fixed={data.why3.childImageSharp.fixed} layout="background" />,
            title: 'Sumérgete en Cenote 3 Zapotes',
            desc:
              'Después de visitar las ruinas, refrescate en las aguas cristalinas de un hermoso cenote natural.',
          },
          {
            image: <Image fixed={data.why4.childImageSharp.fixed} layout="background" />,
            title: 'Visita Playa del Carmen',
            desc:
              'Para el final, disfruta de un tiempo libre en la 5ta avenida, una de las más famosas de Playa del Carmen en donde podrás encontrar souvenirs, bebidas refrescantes o un delicioso snack.',
          },
        ]}
      />

      <ChecklistCTA />

      <Section title="¿De qué se tratá la experiencia?" titleAlign="center">
        <RowContent>
          <ColImage
            image={<Image fixed={data.family.childImageSharp.fixed} layout="background" />}
          />
          <ColInfo
            title="Conoce el Tour 4x1 con Toda tu Familia"
            subtitle={
              <Stack spacing={4}>
                <P>
                  El tour 4x1 es el único que te ofrece más por tu dinero, aquí podrás visitar dos
                  de las ruinas más importantes en la zona, un hermoso cenote y conocer unas de las
                  zonas más turísticas de playa del carmen.
                </P>
                <P>
                  En este Tour, tú y tu familia visitarán las zonas arqueológicas con guía y
                  traductor certificado, para que no se pierdan ningún detalle de la cultura maya.
                </P>
                <P>
                  También por el camino podrán conocer el hermoso cenote de 3 zapotes, recorre la
                  5ta Av. de Playa del Carmen y disfrutar de una deliciosa comida tipo buffet.
                </P>
                <P fontWeight="bold">
                  No te pierdas de esta maravillosa experiencia a un precio inigualable.
                </P>
              </Stack>
            }
          />
        </RowContent>
      </Section>

      <Section title="¿Por qué reservar con nosotros?" titleAlign="center">
        <Features />
      </Section>

      <ChecklistCTA />

      <Section />

      {/* <Faqs
        title="Preguntas Frecuentes"
        list={[
          { question: 'Pregunta 1', answer: 'Respuesta 1' },
          { question: 'Pregunta 2', answer: 'Respuesta 2' },
          { question: 'Pregunta 3', answer: 'Respuesta 3' },
          { question: 'Pregunta 4', answer: 'Respuesta 4' },
        ]}
      /> */}

      {/* <FloatingBook
        title={t('landing:earlybooking.floatingBookNow.title')}
        desc={t('landing:earlybooking.floatingBookNow.desc')}
        colorScheme="red"
      /> */}
    </LandingPage>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "landing/4x1/hero-background.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/4x1/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    why1: file(relativePath: { eq: "landing/4x1/why1.jpg" }) {
      ...fixedImage
    }

    why2: file(relativePath: { eq: "landing/4x1/why2.jpg" }) {
      ...fixedImage
    }

    why3: file(relativePath: { eq: "landing/4x1/why3.jpg" }) {
      ...fixedImage
    }

    why4: file(relativePath: { eq: "landing/4x1/why4.jpg" }) {
      ...fixedImage
    }

    family: file(relativePath: { eq: "landing/4x1/family.jpg" }) {
      ...fixedImage
    }
  }
`

export default SummerSpecialPage
